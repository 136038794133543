import { PlatonModule } from "./store/platon/index"

import vmodal from "vue-js-modal"
import PortalVue from "portal-vue"
import VueChimera from "vue-chimera"
import { AxiosInstance, AxiosRequestConfig } from "axios"

import "./global.scss"
import { formatTime, prettyBytes } from "@Platon/core/helpers"
import "@Platon/core/http/HttpHelpers"
import "@Platon/core/polyfill/index.js"

import "vue-bootstrap-datetimepicker-adizbek"
import Vuelidate from "vuelidate"
import PlatonMixin from "@Platon/PlatonMixin"
import { isValidLocale, loadTranslations, localeInfo } from "@Platon/core/translations"
import { setPlatonHttpClient, setUserFn } from "@Platon/core/core"
import {
	extendVuePrototype,
	registerComponents,
	registerGlobalLibs,
	registerPlatonRoutes
} from "@Platon/PlatonComponents"
import CreateHttpClient from "@Platon/core/http/CreateHttpClient"

import { BootstrapVue } from "bootstrap-vue"
import { API_BASE_URL, DevLog, SERVER_ENDPOINT, IS_DEV, PLATON_LOGIN_PAGE, PLATON_LOGIN_AUTH_PAGE } from "@Platon/const"
import { numberFormat } from "@Platon/core/helpers/numberHelpers"
import { errorToast } from "@Platon/core/functions/toast"
import TopbarContacts from "@Platon/components/layout/TopbarContacts.vue"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import { IMaskDirective } from "vue-imask"
import App from "@Platon/PlatonApp"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import { transformJsToComponent } from "@Platon/components/pages/PageUtils"
import clickOutside from "@Platon/directives/clickOutside"
import VCalendar from "v-calendar"
import { getToken, removeCookie } from "./core/helpers/tokenHelpers"

/**
 * @typedef PlatonCoreOptions
 * @property {Store} store
 * @property {VueRouter} router
 * @property {EndpointDef} chimeraOptions
 * @property {AxiosRequestConfig} axiosOptions
 *
 * @property {(PlatonPlugin|PlatonPluginRegisterOptions)[]} plugins
 *
 * @property {Vue.Component} sidebarProfile
 * @property {Vue.Component} sidebarMenu
 * @property {Vue.Component} sidebarFooter

 * @property {Vue.Component} topbarRight
 * @property {Vue.Component} topbarLeft
 */

/**
 * @param {VueRouter} router
 * @param {Route} toRoute
 * @return {null|*}
 */
const loginPageConst = PLATON_LOGIN_PAGE || process.env.VUE_APP_LOGIN_PAGE
function navigateToLoginPage(router, toRoute) {
	const loginPage = loginPageConst
	const authPage = PLATON_LOGIN_AUTH_PAGE || process.env.VUE_APP_LOGIN_AUTH_PAGE
	// Agar login page uchun full url ko'rsatilgan bo'lsa redirect qilinadi
	if (loginPage.match(/^(http|https)/gi)) {
		window.location.href = loginPage

		return null
	} else if (authPage && authPage.startsWith("/auth") && !IS_DEV) {
		window.location.href = window.location.origin + authPage

		return null
	} else {
		let next = undefined

		if (toRoute && toRoute.name !== "login") {
			next = toRoute.fullPath
		}

		return router.push({
			path: loginPage,
			query: {
				next
			}
		})
	}
}

let projectInfoLoaded = false

/**
 * @param {Vue} Vue
 * @param {function} next
 */
async function loadProjectInfo(Vue, next) {
	try {
		const pageResponse = await $http.get("/info", {
			baseURL: API_BASE_URL
		})

		Vue.prototype.$projectInfo = pageResponse.data

		if (pageResponse.data && pageResponse.data.components) {
			pageResponse.data.components.map((/** @type {DynamicComponent} */ c) => {
				Vue.component(c.name, transformJsToComponent(c))

				if (c.css) Vue.prototype.addCss(undefined, c.css)
			})
		}
	} catch (e) {
		errorToast(e.message)
	}
}

const PlatonCore = {
	/**
	 * @param {Vue} Vue
	 * @param {VueRouter} router
	 * @param {PlatonCoreOptions} options
	 */
	registerRoutes(Vue, router, options) {
		window.Vue = window.PlatonVue = Vue

		let { store } = options

		registerPlatonRoutes(router, options)

		router.beforeEach(async (/** Route */ to, from, next) => {
			DevLog("PlatonRouteBeforeEach", to, from)

			if (!projectInfoLoaded) {
				await loadProjectInfo(Vue, next)

				projectInfoLoaded = true
			}

			if (to.meta.auth === false || to.meta.publicRoute === true) {
				if (to.name === "home") {
					if (Vue.prototype.$projectInfo.has_main) {
						return next()
					}
					return next({ name: "profile-page" })
				} else {
					return next()
				}
			}

			const token = getToken("auth_token")
			const authUser = localStorage.getItem("auth_user")
			const hasCredentials = !!authUser && token

			DevLog("PlatonRouteBeforeEach:hasCredentials", hasCredentials)

			// agar route / bo'lsa va main page bo'lsa main page ga ketamiz
			if (to.path === "/" && Vue.prototype.$projectInfo && Vue.prototype.$projectInfo.has_main) {
				return next()
			}

			// agar login qilinmagan va hozir login pageda bo'lmasa login pagega yuboramiz
			if (!token && to.name !== "login") {
				return navigateToLoginPage(router, to)
			} else if ((!token || to.name === "login") && loginPageConst.startsWith("http")) {
				// agar login qilinmagan va login page boshqa domenga bo'lsa login pagega yuboramiz

				return navigateToLoginPage(router, to)
			} else if (token && !store.state.platon.isAuth) {
				try {
					await PlatonPluginCore.triggerEvent(PluginEvents.BeforeAuth)

					let user = await $api("/user-info", {
						baseURL: API_BASE_URL
					})

					user = user.data.data

					store.commit({
						type: "platon/doLogin",
						token: token,
						user: user,
						saveLoginPage: false
					})

					await PlatonPluginCore.triggerEvent(PluginEvents.AfterAuth, user, token)
				} catch (e) {
					errorToast(e.message)

					removeCookie("auth_token")
					localStorage.removeItem("auth_user")

					return navigateToLoginPage(router, to)
				}
			}

			router.afterEach((/** Route */ to, from) => {
				let newLocale = to.query.locale

				if (newLocale && isValidLocale(newLocale)) {
					localeInfo.locale = newLocale
				}
			})

			// choose orgId

			return next()
		})
	},

	/**
	 * @param Vue
	 * @param {EndpointDef} options
	 * @param {AxiosRequestConfig} axiosConfig
	 * @param {PlatonCoreOptions} platonCoreOptions
	 */
	registerChimera(Vue, options = {}, axiosConfig, platonCoreOptions) {
		let { store, router } = platonCoreOptions
		if (!options.axios) {
			options.axios = CreateHttpClient(axiosConfig)
		}

		/** @type AxiosInstance */
		let $http = options.axios

		$http.interceptors.response.use(null, (error) => {
			let route = platonCoreOptions.router.currentRoute

			// if route doesn't requires authorization just throw error
			if (route && route.meta && route.meta.auth === false) return Promise.reject(error)

			if (error.response && error.response.status === 401) {
				store.commit("platon/doLogout")

				navigateToLoginPage(router, route)
			}
			return Promise.reject(error)
		})

		Vue.use(VueChimera, {
			...options
		})

		setPlatonHttpClient($http)
		setUserFn(function () {
			return store.getters["platon/user"]
		})
	},

	/**
	 *
	 * @param Vue
	 * @param {PlatonCoreOptions} options
	 */
	install(Vue, options) {
		registerGlobalLibs()
		registerComponents(Vue)

		if (options.sidebarFooter) Vue.component("custom-sidebar-footer", options.sidebarFooter)

		if (options.sidebarProfile) Vue.component("custom-sidebar-profile", options.sidebarProfile)

		if (options.sidebarMenu) Vue.component("custom-sidebar-menu", options.sidebarMenu)

		if (options.topbarRight) Vue.component("custom-topbar-right", options.topbarRight)
		else Vue.component("custom-topbar-right", TopbarContacts)

		if (options.topbarLeft) Vue.component("custom-topbar-left", options.topbarLeft)
		if (options.sidebarProject) Vue.component("custom-sidebar-project", options.sidebarProject)

		Vue.filter("prettyBytes", prettyBytes)
		Vue.filter("time", formatTime)
		Vue.filter("numberFormat", numberFormat)
		Vue.directive("imask", IMaskDirective)
		Vue.directive("click-outside", clickOutside)

		//####### register Vuelidate ##
		Vue.use(Vuelidate)

		//#### install boostrap-vue ##
		Vue.use(BootstrapVue)
		//############################

		//#### install v-calendar ##
		Vue.use(VCalendar)

		//############################

		//#### install datepicker ##
		this.installDatePicker()
		//##########################

		//####### Modals
		Vue.use(vmodal)

		//####### Vue Portal
		Vue.use(PortalVue)
		if (!options.axiosOptions) options.axiosOptions = { baseURL: SERVER_ENDPOINT }

		this.registerChimera(Vue, options.chimeraOptions, options.axiosOptions, options)

		if (options) {
			if (options.store) {
				options.store.registerModule("platon", PlatonModule)
			}

			if (options.router) {
				this.registerRoutes(Vue, options.router, options)
			}
		}

		extendVuePrototype(Vue, options)

		Vue.mixin(PlatonMixin)

		loadTranslations()

		new Vue({
			store: options.store,
			router: options.router,
			render: (h) => h(App),
			mixins: [NavigationMixin]
		}).$mount("#app")
	},

	installDatePicker() {
		window.jQuery.extend(true, window.jQuery.fn.datetimepicker.defaults, {
			icons: {
				time: "far fa-clock",
				date: "far fa-calendar-alt",
				up: "fas fa-arrow-up",
				down: "fas fa-arrow-down",
				previous: "fas fa-chevron-left",
				next: "fas fa-chevron-right",
				today: "fas fa-calendar-check",
				clear: "far fa-trash-alt",
				close: "far fa-times-circle"
			}
		})
	}
}

export default PlatonCore
